<template>
  <div class="page-wrap">
    <div class="top-bar">
      <mtop />
    </div>
    <div class="banner">
      <div class="inner">
        <div class="left">
          <div class="title">达摩大模型</div>
          <div class="desc">善达信息凭借深厚的人工智能技术积累与创新能力，隆重推出达摩大模型。致力于为企业提供一站式、高度定制化的私有化大模型，具备高效、精准、安全的数据处理能力，助力企业在激烈的市场竞争中脱颖而出，加速数字化转型步伐。</div>
          <div class="btn" @click="dialogVisible = true, clickMenu('免费试用')">免费试用</div>
        </div>
      </div>
    </div>

    <!-- 构建行业垂类模型全面赋能企业客户 -->
    <div class="section bg-white kh">
      <div class="title">构建行业垂类模型全面赋能企业客户</div>
      <div class="content">
        <div class="bg-kh"></div>
      </div>
    </div>

    <!-- 达摩大模型构建流程思路 -->
    <div class="section sl">
      <div class="title">达摩大模型构建流程思路</div>
      <div class="content">
        <div class="bg-sl"></div>
      </div>
    </div>

    <!-- 达摩大模型核心能力 -->
    <div class="section bg-white abl">
      <div class="title">达摩大模型核心能力</div>
      <div class="content">
        <div class="bg-abl"></div>
      </div>
    </div>

    <!-- 产品优势 -->
    <div class="section adv">
      <div class="title">产品优势</div>
      <div class="content">
        <div class="adv-list">
          <div class="item" v-for="item in advList" :key="item.name">
            <div class="icon">{{item.name}}</div>
            <div class="info">
              <div class="title">{{item.title}}</div>
              <div class="desc">{{item.desc}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 应用场景 -->
    <div class="section bg-white scene">
      <div class="title">应用场景</div>
      <div class="content">
        <div class="bg-scene"></div>
      </div>
    </div>

    <!-- 客户案例 -->
    <div class="section case">
      <div class="title">客户案例</div>
      <div class="content">
        <div class="detail-case">
          <div class="left">
            <div class="title">项目背景</div>
            <div class="info-item">
              <div class="title">专业知识学习难，传统检索不便</div>
              <div class="content">
                -知识图谱构建缺失，仅有传统知识<br/>
                -层级知识复杂，检索过程困难，溯源困难
              </div>
              <div class="btn">利用水利数据库进行模型训练</div>
            </div>
            <div class="info-item">
              <div class="title">水利经验积累难，专业人才培养少</div>
              <div class="content">
                -水利行业壁垒较高，尤其在运维及设备管理方面，人才匮乏<br/>
                -水利专业知识学习难度大，术语多
              </div>
              <div class="btn">构建水利系统专家智能体</div>
            </div>
          </div>
          <div class="right">
            <div class="top">
              <img src="@/assets/gpt-private/img-case.png" />
            </div>
            <div class="bottom">
              <div class="item">
                <div class="count">80000+</div>
                <div class="name">水利知识点整理数据量</div>
              </div>
              <div class="item">
                <div class="count">170W+</div>
                <div class="name">学习知识文字数量</div>
              </div>
              <div class="item">
                <div class="count">提高20倍</div>
                <div class="name">水利问题溯源效率</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 合作伙伴 -->
    <partner/>

    <fotterBanner></fotterBanner>
    <mbottom />

    <demonstrate
        :dialogVisible="dialogVisible"
        @closeDialog="dialogVisible = false"
    ></demonstrate>
  </div>
</template>

<script>
import mtop from "@/components/top.vue";
import Partner from "@/components/partner";
import mbottom from "@/components/bottom.vue";
import fotterBanner from "@/components/fotterBanner.vue";
import demonstrate from "@/components/demonstrate.vue";
import {staClickMenu} from "@/utils";
export default {
  components: {
    Partner,
    mtop,
    mbottom,
    fotterBanner,
    demonstrate
  },
  data() {
    return {
      dialogVisible: false,
      advList: [
        {
          name: '定制',
          title: '高度定制化',
          desc: '根据企业特定业务需求与数据特性，量身定制大模型， 确保模型精准匹配企业应用场景。'
        },
        {
          name: '安全',
          title: '数据安全性',
          desc: '数据全程在企业内部处理，无需外传，严格遵守数据隐私与安全法规，保障企业信息安全。'
        },
        {
          name: '卓越',
          title: '性能卓越',
          desc: '采用先进深度学习算法与高性能计算技术，实现快速训练与高效推理，显著提升业务处理效率。'
        },
        {
          name: '进化',
          title: '持续进化',
          desc: '支持模型在线学习与更新，随着企业数据积累与业务变化，不断优化模型性能，保持竞争力。'
        }
      ]
    }
  },
  methods: {
    getImage(item) {
      return require(`@/assets/agr-prod/${item.icon}`)
    },
    clickMenu(menuName, url) {
      if (url) {
        this.$router.replace({
          path: url,
        });
      } else {
        if (menuName != '免费试用') {
          this.$toast("敬请期待");
        }
      }
      staClickMenu(menuName)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
